import styles from "./index.module.css";
import PropTypes from "prop-types";

import WWG from "../../Images/WWG_Bug.svg";
import Print from "../../Images/Print_Icon.svg";

import ReceiptItem from "components/ReceiptItem";
import { Button } from "components/Button";
import { useParams } from "react-router-dom";
import { SignUpContainer } from "hooks/useSignUp";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcAmex,
  faCcApplePay,
  faCcMastercard,
  faCcDinersClub,
  faCcDiscover,
  faCcAmazonPay,
  faCcJcb,
  faCcPaypal,
  faCcStripe,
} from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import environment from "shared/environment";
import moment from "moment/moment";
import mobile from "mobileBridge";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

/**
 * Receipt displayed on the final page
 */

export default function Receipt({
  iboNumber,
  confirmation,
  name,
  orderDate,
  renewalDate,
  total,
  items = [],
  payment,
  billingAddress,
}) {
  const { currencyFormatter } = SignUpContainer.useContainer();

  const notTaxItems = useMemo(() => {
    return items.filter((a) => !a.Description.toLowerCase().includes("tax"));
  }, [items]);

  const taxItems = useMemo(() => {
    return items.filter((a) => a.Description.toLowerCase().includes("tax"));
  }, [items]);

  const subTotal = useMemo(() => {
    return notTaxItems.reduce((p, v) => {
      return p + v.Amount;
    }, 0);
  }, [items]);

  const totalTax = useMemo(() => {
    return taxItems.reduce((p, v) => {
      return p + v.Amount;
    }, 0);
  }, [items]);

  const cardIcon = useMemo(() => {
    if (payment.IsCard) {
      if (payment.Card === "VISA".toUpperCase()) {
        return <FontAwesomeIcon icon={faCcVisa} className={styles.iconClass} />;
      } else if (payment.Card === "AMERICAN EXPRESS".toUpperCase()) {
        return <FontAwesomeIcon icon={faCcAmex} className={styles.iconClass} />;
      } else if (payment.Card === "DINERS CLUB INTERNATIONAL".toUpperCase()) {
        return (
          <FontAwesomeIcon icon={faCcDinersClub} className={styles.iconClass} />
        );
      } else if (payment.Card === "Discover Card".toUpperCase()) {
        return (
          <FontAwesomeIcon icon={faCcDiscover} className={styles.iconClass} />
        );
      } else if (payment.Card === "JCB".toUpperCase()) {
        return <FontAwesomeIcon icon={faCcJcb} className={styles.iconClass} />;
      } else if (payment.Card === "MasterCard".toUpperCase()) {
        return (
          <FontAwesomeIcon icon={faCcMastercard} className={styles.iconClass} />
        );
      }
      return <>Credit Card: </>;
    } else if (payment.IsCard == false) {
      return <>Bank Account: </>;
    } else {
      return <>{payment.Description}</>;
    }
  }, [payment]);

  const handleMobilePrint = async () => {
    mobile.openLink(`${window.location}/receipt`);
  };

  // const handleMobileDownload = async () => {
  //   const element = document.getElementById("print"),
  //     canvas = await html2canvas(element),
  //     data = canvas.toBlob(function (blob) {
  //       saveAs(blob, `receipt_${confirmation}.png`);
  //     }, "image/png");
  // };

  const renewDateString = () => {
    var dateFragments = renewalDate.substring(0, 10).split("-");
    var formatDate = `${dateFragments[1]}-${dateFragments[2]}-${dateFragments[0]}`;
    return formatDate;
  };

  return (
    <>
      <div className={styles.receipt} id="print">
        <div className={styles.header}>
          <img src={WWG} />
          <div>Membership Receipt</div>
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.row}>
              <div className={styles.label}>IBO Number:</div>
              <div className={styles.value}>{iboNumber}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Name:</div>
              <div className={styles.value}>{name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Confirmation:</div>
              <div className={styles.value}>{confirmation}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Order Date:</div>
              <div className={styles.value}>
                {moment(orderDate).format("MM-DD-YYYY")}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Renewal Date:</div>
              <div className={styles.value}>{renewDateString()}</div>
            </div>
          </div>
        </div>

        <div className={styles.completed}>Order Completed</div>
        <div className={styles.items}>Item(s) Purchased</div>
        {items
          .filter((a) => !a.Description.toLowerCase().includes("tax"))
          .map((a) => {
            return (
              <ReceiptItem
                key={a.Description}
                itemName={a.Description}
                price={a.Monthly}
                amount={a.Amount}
                proCreditPrice={a.Amount}
              />
            );
          })}
        <div className={styles.items}>
          <ReceiptItem
            key={"Subtotal"}
            itemName={"Subtotal"}
            amount={subTotal}
            divider={false}
            containerClassName={styles.subTotalContainer}
            amountClassName={styles.subTotalAmount}
          />
          <ReceiptItem
            key={"Tax"}
            itemName={"Tax"}
            amount={totalTax}
            divider={false}
            containerClassName={styles.subTotalContainer}
            amountClassName={styles.subTotalAmount}
          />
          <ReceiptItem
            className={styles.total}
            key={"Total"}
            itemName={"Order Total"}
            amount={subTotal + totalTax}
            divider={false}
            containerClassName={styles.totalContainer}
            amountClassName={styles.totalAmount}
          />
        </div>

        <div className={styles.billingAddressContainer}>
          <div className={styles.billingAddressHeader}>Billing Address</div>
          <div className={styles.billingAddress}>{billingAddress.Line1}</div>
          {billingAddress.Line2 && (
            <div className={styles.billingAddress}>{billingAddress.Line2}</div>
          )}
          <div className={styles.billingAddress}>
            {billingAddress.City}, {billingAddress.State} {billingAddress.Zip},{" "}
            {billingAddress.Country}
          </div>
        </div>

        <div className={styles.paymentMethodContainer}>
          <div className={styles.paymentMethodHeader}>Payment Method</div>
          <div className={styles.paymentMethod}>
            <div
              className={classNames([
                styles.paymentMethodIcon,
                payment.IsCard ? styles.paymentMethodBigIcon : "",
              ])}
            >
              {cardIcon}
            </div>
            <div className={styles.paymentMethodLastFour}>
              {payment.CardLastFour || payment.BankLastFour}
            </div>
          </div>
        </div>
        <div className={styles.controlsContainer}>
          <div className={styles.appears}>
            Appears on your credit card statement as Dreambuilders Membership
          </div>
        </div>
      </div>

      <div className={styles.controls}>
        <Button
          className={styles.print}
          title={"Print"}
          onClick={() => {
            mobile.isApp() ? handleMobilePrint() : window.print();
          }}
        >
          <img src={Print} className={styles.printer} />
        </Button>
      </div>
    </>
  );
}

Receipt.propTypes = {
  iboNumber: PropTypes.string,
  name: PropTypes.string,
  confirmation: PropTypes.string,
  orderDate: PropTypes.string,
  renewalDate: PropTypes.string,
};
