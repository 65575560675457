import styles from "./index.module.css";
import classNames from "classnames";

import WWG from "../../Images/WWG_Bug.svg";
import InfoCenter from "../../Images/Info_Center_PDF_Icon.svg";
import DS from "../../Images/DreamStream_Bug.svg";
import digital from "../../Images/digital.svg";
import topics from "../../Images/topics.svg";

import mobile from "mobileBridge";
import environment from "shared/environment";

/*
 * Card displayed on the welcome page
 */

export default function WelcomeCard({ dreamStreamAccess }) {
  const pdfLink =
    environment.WEB_APP_COUNTRY === "US"
      ? "https://wwguscms.blob.core.windows.net/files/cms/381/2095f1e0-c21a-11ee-9074-bbe0bff83cc1/Dreambuilders%20Membership%20Features%2020240206_CM.pdf"
      : "https://wwguscms.blob.core.windows.net/files/cms/381/2095f1e0-c21a-11ee-9074-bbe0bff83cc1/Dreambuilders%20Membership%20Features_20240410_ANZ.pdf";
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={styles.line}></div>
        <img src={WWG} alt="WWG" />
        <div className={styles.line}></div>
      </div>
      <div className={styles.row2}>
        <div>Dreambuilders Premier Membership</div>
      </div>
      <div className={styles.row2}></div>
      <div className={styles.features}>
        {!mobile.isApp() ? (
          <a href={pdfLink} target="_blank" rel="noreferrer">
            <img
              className={styles.img}
              src={InfoCenter}
              alt="download link icon"
            />
            Dreambuilders Membership Features
          </a>
        ) : (
          <div onClick={() => mobile.viewPDF(pdfLink)}>
            <img
              className={styles.img}
              src={InfoCenter}
              alt="download link icon"
            />
            Dreambuilders Membership Features
          </div>
        )}
      </div>

      <div className={styles.row}>
        <div className={styles.line}></div>
        <img src={digital} alt="Digital Delivery" />
        <div className={styles.line}></div>
      </div>
      <div className={styles.row2}>
        <div>Digital Delivery</div>
      </div>

      <div className={styles.row}>
        <div className={styles.line}></div>
        <img src={DS} alt="Dream Stream" />
        <div className={styles.line}></div>
      </div>
      <div className={styles.row2}>
        <div>DreamStream®</div>
      </div>
      {dreamStreamAccess && (
        <>
          <div className={classNames(styles.row, styles.kate)}>
            <div className={styles.line}></div>
            <img src={topics} alt="Topics" />
            <div className={styles.line}></div>
          </div>
          <div className={styles.row2}>
            <div>Topics™ by DreamStream</div>
          </div>
        </>
      )}
    </div>
  );
}
