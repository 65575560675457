import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import { Button } from "components/Button";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { SignUpContainer } from "hooks/useSignUp";

/**
 * Custom modal that appears at the bottom of the page.
 */

const customStyles = {
  content: {
    top: "auto",
    bottom: "-2%",
  },
};

export function FooterModal({
  visible,
  onCancel,
  onClick,
  selectedSubscription,
  topics,
}) {
  const { currencyFormatter } = SignUpContainer.useContainer();

  if (!selectedSubscription) {
    return null;
  }

  const topicsAmount = topics ? Number(topics.amount) : 0;
  const topicsProrated = topics ? Number(topics.proratedAmount) : 0;

  const _amount = Number(
    !isNaN(selectedSubscription.amount)
      ? Number(selectedSubscription.amount) + topicsAmount
      : 0.0
  );
  const _memberTax = Number(
    !isNaN(selectedSubscription.memberTax)
      ? selectedSubscription.memberTax
      : 0.0
  );
  const _digitalTax = Number(
    !isNaN(selectedSubscription.digitalTax)
      ? selectedSubscription.digitalTax
      : 0.0
  );
  const _tax = _memberTax + _digitalTax;

  const monthlyCurrency = currencyFormatter.format(_amount);
  const taxCurrency = _tax > 0 ? `+ tax ${currencyFormatter.format(_tax)}` : "";

  const _proratedAmount = Number(
    !isNaN(selectedSubscription.proratedAmount)
      ? Number(selectedSubscription.proratedAmount) + topicsProrated
      : 0.0
  );
  const _proratedMemberTax = Number(
    !isNaN(selectedSubscription.proratedMemberTax)
      ? selectedSubscription.proratedMemberTax
      : 0.0
  );
  const _proratedDigitalTax = Number(
    !isNaN(selectedSubscription.proratedDigitalTax)
      ? selectedSubscription.proratedDigitalTax
      : 0.0
  );
  const _proratedTax =
    _proratedAmount + _proratedMemberTax + _proratedDigitalTax;
  const proratedCurrency =
    _proratedTax > 0
      ? currencyFormatter.format(_proratedTax)
      : currencyFormatter.format(0);

  return (
    <Modal isOpen={visible} onRequestClose={onCancel} style={customStyles}>
      <div
        className={classNames(styles.container, {
          [styles.show]: visible,
        })}
      >
        <div className={styles.innerContainer}>
          <div>
            <div className={styles.infoGroup}>
              <div className={styles.label}>Monthly: </div>
              <div
                className={styles.price}
              >{`${monthlyCurrency} ${taxCurrency}`}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.label}>Prorated Later: </div>
              <div className={styles.price}>{proratedCurrency}</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.label}>Today's Total: </div>
              <div className={styles.price}>{currencyFormatter.format(0)}</div>
            </div>
          </div>

          <div className={styles.btnGroup}>
            <Button
              title={"Cancel"}
              className={styles.btnColor2}
              onClick={onCancel}
            ></Button>
            <Button
              title={"Start Membership"}
              className={styles.btnColor}
              onClick={onClick}
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

FooterModal.propTypes = {
  visible: PropTypes.bool, // Boolean to show and hide modal
  onCancel: PropTypes.func, // function to set visible to false
  onClick: PropTypes.func,
};
