import styles from "./index.module.css";
import WelcomeCard from "components/WelcomeCard";
import WelcomeHeader from "components/WelcomeHeader";
import WelcomeImg from "../../Images/Welcome.svg";
import NextButton from "components/NextButton";
import { Link, useNavigate } from "react-router-dom";
import { SignUpContainer } from "hooks/useSignUp";
import { useEffect } from "react";

/**
 * Welcome page displaying membership prices. Once get started is clicked the user is taken to the personal info section.
 */

export default function Welcome() {
  const { signUpValueRef } = SignUpContainer.useContainer();
  const iboNum = signUpValueRef.current.iboNum;
  const dreamStreamAccess = signUpValueRef.current?.dreamStreamAccess;
  let navigate = useNavigate();

  useEffect(() => {
    if (!iboNum) {
      navigate("/login");
    }
  }, [iboNum, navigate]);

  return iboNum ? (
    <div className={styles.welcome}>
      <WelcomeHeader img={WelcomeImg} className={styles.header} />
      <WelcomeCard dreamStreamAccess={dreamStreamAccess} />
      <div className={styles.container}>
        <i className={styles.msg}>
          Premier Membership, Digital Delivery,{dreamStreamAccess ? "" : " and"}{" "}
          DreamStream®{dreamStreamAccess ? ", and Topics™ by DreamStream" : ""}{" "}
          are complimentary for the first year of your Amway IBO contract.
        </i>
      </div>
      <div className={styles.container}>
        <Link to={"/info"} className={styles.link}>
          <NextButton
            title={"Get Started!"}
            arrow={"none"}
            className={styles.backgroundOne}
          />
        </Link>
      </div>
    </div>
  ) : null;
}
