import PersonalInfoHeader from "components/PersonalInfoHeader";
import styles from "./index.module.css";
import NextButton from "components/NextButton";
import { Ok, IkonateContext } from "react-ikonate";
import MembershipChoiceItem from "components/MembershipChoiceItem";
import PropTypes from "prop-types";

import Premier from "../../Images/Premier_Icon.svg";
import PremierDigital from "../../Images/Premier_Digital_Icon.svg";

/**
 * Membership choice step with info about each option. The user has the choice to continue with out without payment method at this step.
 */

export default function ChooseYourMembership({
  visible,
  setVisible,
  iboNum,
  onBack,
  selectedSubscription,
  setSelectedSubscription,
  noCard,
  setNoCard,
  subscriptions,
  onNext,
  dreamStreamAccess,
}) {
  const getSubsriptionImage = (subscription) => {
    const data = subscriptionConstants.find(
      (a) => a.type === subscription.type
    );
    return data && data.img ? data.img : null;
  };
  console.log(dreamStreamAccess);
  const getTotal = (subscriptions) => {
    let amount = 0.0;
    subscriptions.map((sub) => {
      if (sub.type === 12) {
        amount = amount + Number(sub.amount);
      }
      if (dreamStreamAccess && sub.type === 65536) {
        amount = amount + Number(sub.amount);
      }
    });
    return amount.toFixed(2).toString();
  };

  const getPriceStr = (subscriptions, type) => {
    if (type === 4 || type === 65536) {
      return subscriptions.find((sub) => sub.type === type)?.amount;
    } else if (type === 8) {
      let pmdd = subscriptions.find((sub) => sub.type === 12)?.amount;
      let pm = subscriptions.find((sub) => sub.type === 4)?.amount;
      return (Number(pmdd) - Number(pm)).toFixed(2).toString();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <PersonalInfoHeader
            iboNumber={iboNum}
            subHeader={"Choose Your Membership"}
          />
        </div>
        <div className={styles.choices}>
          {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map((a) => {
              // for now, only want pm + dd option shown for new trial memberships
              if (a.type === 12) {
                return (
                  <MembershipChoiceItem
                    key={a.title}
                    selected={selectedSubscription === a}
                    onClick={() => setSelectedSubscription(a)}
                    img={getSubsriptionImage(a)}
                    title={
                      dreamStreamAccess
                        ? "Premier Membership +"
                        : "Premier Membership + Digital Delivery"
                    }
                    price={getTotal(subscriptions)}
                    amountDue={"0"}
                    amountDueAfter={`${a.proratedAmount}`}
                  />
                );
              }
            })}
        </div>
        {subscriptions && subscriptions.length > 0 && (
          <div className={styles.infoSection}>
            <div className={styles.infoHeader}>What's Included?</div>
            <div className={styles.info}>
              <div className={styles.emphasis}>
                All of these features are available to you, complimentary for
                your first 12 months!
              </div>
            </div>

            <div className={styles.info}>
              <ul>
                <li>
                  Premier Membership{" "}
                  <span className={styles.normal}>
                    (${getPriceStr(subscriptions, 4)}/mo value)
                  </span>
                </li>
                <li>
                  Digital Delivery{" "}
                  <span className={styles.normal}>
                    (${getPriceStr(subscriptions, 8)}/mo. value)
                  </span>
                </li>
                {dreamStreamAccess && (
                  <>
                    <li>
                      DreamStream®{" "}
                      <span className={styles.normal}>
                        (${getPriceStr(subscriptions, 65536)}
                        /mo. value)
                      </span>
                    </li>
                    <li className={styles.indent}>
                      Topics™{" "}
                      <span className={styles.normal}>by DreamStream</span>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className={styles.ccInfo}>
              A credit card is required to sign up for Premier Membership. Your
              credit card will not be charged until your complimentary period
              expires.
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <div className={styles.fill1}></div>
          <div className={styles.fill2}></div>
        </div>
        <div className={styles.controls}>
          <div>
            <NextButton
              arrow={"left"}
              className={styles.backgroundTwo}
              onClick={onBack}
            />
          </div>
          <div className={styles.next}>
            <div>
              <NextButton title={"Next"} arrow={"right"} onClick={onNext} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ChooseYourMembership.propTypes = {
  visible: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // Used to display footer modal
  setVisible: PropTypes.func, // open footer modal
  iboNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBack: PropTypes.func, // function passed in for back button
  setStep: PropTypes.func, // function passed in to set next step
  membershipChoice: PropTypes.string, // displays selected membership choice
  setMembershipChoice: PropTypes.func, // sets selected membership choice
  noCard: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // if no card checkbox is checked
  setNoCard: PropTypes.func, // sets if no card checkbox is checked
};

const subscriptionConstants = [
  { type: 4, img: Premier },
  { type: 12, img: PremierDigital },
];
