import { Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import AllSet from "pages/AllSet";
import Welcome from "pages/Welcome";
import Information from "pages/Information";
import Invite from "pages/Invite";
import Code from "pages/Code";
import { Auth, LoginGuard } from "./shared/auth";

export function App() {
  window.addEventListener("load", () => {
    setTimeout(() => {
      let element = document.getElementById("launcher");
      if (element) document.getElementById("launcher").style.display = "none";
    }, 500);
  });

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        exact
        path="/"
        element={
          <Auth.Provider>
            <LoginGuard>
              <Invite />
            </LoginGuard>
          </Auth.Provider>
        }
      />
      <Route
        path="/code"
        element={
          <Auth.Provider>
            <LoginGuard>
              <Code />
            </LoginGuard>
          </Auth.Provider>
        }
      />
      <Route path="/invite/:code" element={<Login />} />
      {/* <Route path="/address/confirmation" element={<AddressConfirmation />} /> */}
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/all-set/:receiptId/:ibo" element={<AllSet />} />
      <Route path="/all-set/:receiptId/:ibo/receipt" element={<AllSet />} />
      <Route path="/info" element={<Information />} />
      {/* <Route path="/card" element={<Payment />} /> */}
    </Routes>
  );
}
